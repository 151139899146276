/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'

export default function useAdminNavigation() {
  const { navigateToLocalePath, getLocalePath, route } = useNavigation()

  const adminDashboardLocalePath = getLocalePath('admin-Dashboard')
  const adminOrganisationsListLocalePath = getLocalePath('admin-organisations-List')
  const adminOrganisationAddLocalePath = getLocalePath('admin-organisations-Add')
  const adminEngagementsListLocalePath = getLocalePath('admin-engagements-List')

  const navigateToAdminOrganisationsListPage = async (): Promise<void> => {
    await navigateToLocalePath(adminOrganisationsListLocalePath)
  }

  const navigateToAdminAddOrganisationSuccessPage = async (
    chamberOfCommerceId: string,
  ): Promise<void> => {
    await navigateToLocalePath(
      getLocalePath({
        name: 'admin-organisations-Success',
        params: {
          chamberOfCommerceId,
        },
      }),
    )
  }

  return {
    route,
    adminDashboardLocalePath,
    adminOrganisationsListLocalePath,
    adminOrganisationAddLocalePath,
    adminEngagementsListLocalePath,
    navigateToAdminOrganisationsListPage,
    navigateToAdminAddOrganisationSuccessPage,
  }
}
